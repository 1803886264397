<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    @close="close"
    width="570px"
  >
    <el-form
      ref="form"
      :inline="true"
      :model="form"
      :rules="rules"
      size="small"
      label-width="88px"
    >
      <el-form-item label="部门名称" prop="name">
        <el-input style="width: 300px" v-model="form.name" />
      </el-form-item>
      <template v-if="form.parentId!= 0">
      <el-form-item label="上级部门" prop="parentId">
        <treeselect
          v-model="form.parentId"
          :options="tableData"
          style="width: 300px"
          placeholder="选择部门"
          :normalizer="normalizer"
        />
      </el-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="close">取消</el-button>
      <el-button type="primary" @click="submit()">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      roles: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        parentId: [
          { required: true, message: "请选择上级部门", trigger: "change" },
        ],
      },
      dialogVisible: true,
      form: {
        name: "",
        parentId: null,
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        parentId: [
          { required: true, message: "请选择上级部门", trigger: "change" },
        ],
      },
    };
  },
  props: {
    tableData: {
      default: [],
    },
    selectItem:{

    }
  },
  mounted(){
    if(JSON.stringify(this.selectItem)!=='{}'){
      // console.log( this.selectItem)
      this.form = this.selectItem;
    }
  },
  methods: {
    normalizer(node) {
      //去掉children=[]的children属性
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
        isDefaultExpanded: true,
      };
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
  },
  components: { Treeselect },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>
