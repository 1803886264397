<template>
  <div>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="showDialog()"
                >新增部门</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            row-key="id"
            border
            lazy
            default-expand-all
            @selection-change="handleSelectionChange"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="部门"></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建日期"
              align="center"
              width="500"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    class="tip-text-edit mr-20"
                    @click="showDialog(scope.row)"
                    ><i class="el-icon-edit-outline">编辑</i></span
                  >
                  <el-popconfirm
                    title="确定删除此分类吗？"
                    @onConfirm="deleteDateOne(scope.row.id)"
                    v-if="!scope.row.children.length"
                  >
                    <i
                      class="el-icon-delete tip-text-edit tip-text-delete"
                      slot="reference"
                      >删除</i
                    >
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <my-add
      v-if="myAddType"
      @close="close"
      :tableData="tableData"
      :selectItem="selectItem"
      @submit="submit"
    ></my-add>
  </div>
</template>
<script>
import myAdd from "./mask/add";
import {
  sysDeptList,
  sysDept,
  SysDeptDel,
  sysDeptPut,
} from "@/api/system/dept";
export default {
  components: {
    myAdd,
  },
  data() {
    return {
      tableData: [], //列表数据
      cateParentList: [],
      multipleSelection: [],
      myAddType: false,
      selectItem: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    selectable(row, index) {
      if (!row.children.length) {
        return true;
      } else {
        return false;
      }
    },
    // 获取分类列表
    getList(id = "") {
      sysDeptList(id).then((res) => {
        this.total = res.data.body.length;
        this.tableData = res.data.body;
      });
    },
    submit(e) {
      if(e.children){
        delete e.children;
      }
      if (JSON.stringify(this.selectItem) !== "{}") {
        sysDeptPut(e).then((res) => {  //修改
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.myAddType = false;
          this.getList();
        });
      } else { //创建
        sysDept(e).then((res) => {
          this.$message({
            type: "success",
            message: res.data.msg,
          });
          this.myAddType = false;
          this.selectItem = {};
          this.getList();
        });
      }
    },
    close() {
      this.myAddType = false;
      this.selectItem = {};
    },
    handleSelectionChange(val) {
      //多选改变
      this.multipleSelection = val;
    },
    deleteDate(data) {
      // 删除
      SysDeptDel(data).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getList();
      });
    },
    deleteDateOne(id) {
      this.deleteDate(id);
    },
    showDialog(item) {
      if (item) {
        // 编辑
        this.selectItem = item;
      }
      this.myAddType = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.tip-text-edit {
  color: #409eff;
  cursor: pointer;
}
.bg-red {
  background: #f56c6c;
}
</style>

